import isServer from "@/_base/isServer";
import Swal from "sweetalert2";
if (!isServer) {
	import("@sweetalert2/theme-bootstrap-4");
}
const $swal = {
	install: (Vue, options) => {
		Vue.config.globalProperties.$swal = Swal.mixin(options);
	},
}
export default $swal;