import { createSSRApp, createApp, h } from "vue";
import isServer from "@/_base/isServer";
import App from "./App.vue";
import createRouter from "./router";
import * as nativeStore from "./store/useNativeStore";
import * as vuexStore from "./store/useVuexStore";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import BootstrapVue3 from "bootstrap-vue-3";
import { BootstrapIconsPlugin } from "bootstrap-icons-vue";
import Swal from "./plugin/swal.js";
import ValidationForm from "./plugin/validation.js";
import $axios from "@/api/http";
import VueLazyload from "@jambonn/vue-lazyload";
import moment from "moment";
import { createHead } from "@vueuse/head";
import './registerServiceWorker.js'

export default function (args) {
	const rootComponent = {
		render: () => h(App),
		components: {
			App,
		},
		setup(props) {
			nativeStore.provideStore(args.nativeStore);
			vuexStore.provideStore(args.vuexStore);
		}
	}

	const app = (isServer ? createSSRApp : createApp)(rootComponent);
	app.config.globalProperties.isServer = isServer;

	const router = createRouter(args.vuexStore, app);

	app.use(args.vuexStore);
	app.use(router);
	app.use(BootstrapVue3);
	app.use(BootstrapIconsPlugin);
	app.use(Swal, {
		reverseButtons: true,
		confirmButtonText: "确定",
		cancelButtonText: "取消",
		confirmButtonColor: "#84bd00",
		cancelButtonColor: "#646464",
	});
	app.use(ValidationForm);
	app.use($axios);
	app.use(VueLazyload, {
		loading: require("./assets/images/common/transparent_350x350.png"),
		error: require("./assets/images/common/transparent_350x350.png"),
	});
	app.config.globalProperties.$filters = {
		currency: (amount) => {
			const p1 = Math.pow(10, 3);
			const p2 = Math.pow(10, 2);
			return typeof amount === "number" ? (Math.round(amount * p1 / 10) / p2).toLocaleString() : (Math.round(Number(amount) * p1 / 10) / p2).toLocaleString();
		},
		notNumberString: (string) => {
			return isNaN(Number(string));
		},
		carbon: (mass) => {
			return mass >= 1000 ? `${(Math.round(mass) / 1000).toFixed(2)}kg` : `${mass}g`;
		},
		date: (dateString, format = "yyyy/MM/DD") => {
			return dateString ? moment(dateString).format(format) : "";
		},
		inCoolingOffPeriod: (deliveredDate) => {
			// 計算「七天鑑賞期」:
			// 根據消費者保護法之規定，享有商品到貨的七日鑑賞期權益，是由消費者完成簽收取件的隔日開始算起至第7天止（如您的收件地址有管理員代收，則以代收的隔日起算喔，請留意送件通知），為七日鑑賞期限。
			// 例如，完成簽收的時間是12/20，其七天鑑賞期起訖日即為12/21~12/27。
			return moment().isBefore(moment(deliveredDate).endOf("day").add(7, "days"));
		},
		isSameDomain: (link) => {
			return isServer ? false : link.startsWith(location.origin);
		},
		htmlAnchorToRouter: (htmlString) => {
			return typeof htmlString === "string" || htmlString instanceof String ? htmlString.replace(/<a (.*?)href="(?!(http:\/\/|https:\/\/))(.*?)"(.*?)target="_self"(.*?)>/g, `<a $1href="$3" onclick="event.preventDefault(); app.__vue_app__.config.globalProperties.$router.push('$3')"$4>`) : undefined;
		},
	}

	const head = createHead();
	app.use(head);
	return {
		app,
		router,
		head,
	};
}
