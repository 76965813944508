const deviceModule = {
	namespaced: true,
	state: {
		deviceInfo: undefined,
		reg: null,
		isOnLine: false,
	},
	getters: {
	},
	mutations: {
		setDeviceInfo(state, deviceInfo) {
			state.deviceInfo = deviceInfo;
		},
		setRegisterSW (state, reg) {
			state.reg = reg;
		},
		setOnLine (state) {
			state.isOnLine = true;
		}
	},
	actions: {
	},
	modules: {
	}
}

export default deviceModule;