/* eslint-disable no-console */
import * as vuexStore from "./store/useVuexStore";
const store = vuexStore._createStore();
import { register } from 'register-service-worker'
if ('serviceWorker' in navigator) {
  if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
      ready () {
      },
      registered (reg) {
	     // 主要是這邊使用 reg.update() 判斷是否要更新
        store.commit("deviceStore/setRegisterSW", reg);
      },
      cached () {

      },
      updatefound () {

      },
      updated (registration) {
        if(!store.state.deviceStore.isOnLine) return // 為了不讓直接進來的時候也重整，所以寫個假如是在 online 時，updated() 才更新 
        window.location.reload(true);
      },
      offline () {

      },
      error (error) {
        console.error('Error during service worker registration:', error)
      }
    })
  }
}