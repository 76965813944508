const pageLoadingModule = {
	namespaced: true,
	state: {
		pageLoading: false,
		loadingMessage: {
			title: "Loading",
			text: "",
		},
		isCloseLoadingRetry: false,
	},
	getters: {
	},
	mutations: {
		pageLoaded(state) {
			state.pageLoading = false;
		},
		setPageLoading(state, status) {
			state.loadingMessage = status;
			state.pageLoading = true;
		},
		closeLoadingRetry(state) {
			state.isCloseLoadingRetry = true;
		},
	},
	actions: {
		closeLoading({ state, commit, dispatch }) {
			return new Promise((resolve, reject) => {
				setTimeout(() => {
					commit("pageLoaded");
					if (state.pageLoading) {
						commit("closeLoadingRetry");
						dispatch("closeLoading");
					} else {
						resolve();
					}
				}, state.isCloseLoadingRetry ? 1000 : 3000);
			});
		},
	},
	modules: {
	}
}

export default pageLoadingModule;