import * as Yup from "yup";
import { Form, Field } from "vee-validate";

const creatOnInvalidSubmit = (vueApp) => {
	const swal = vueApp.config.globalProperties.$swal;
	const actionAfterAlert = (inputElement) => {
		if (inputElement.getAttribute("type") === "v-date-picker") {
			inputElement.click();
		} else {
			inputElement.focus();
		}
	}
	const onInvalidSubmit = ({ values, errors, results, evt }) => {
		let firstErrorFieldName = Object.keys(errors)[0];
		swal.fire({
			title: results[firstErrorFieldName].errors[0],
			icon: "warning",
			timer: 2000,
			didClose: () => {
				let element = evt.target.querySelector(`[name=${firstErrorFieldName}]`);
				let elementToCenterOffset = window.pageYOffset + element.getBoundingClientRect().top + element.offsetHeight / 2 - window.innerHeight / 2;
				if (
					// 視窗位置在最頂部而且目標標籤在視窗中間位置以下
					window.pageYOffset === 0 && elementToCenterOffset > 0 ||
					// 視窗位置不在最頂部而且目標標籤不再視窗中間位置
					window.pageYOffset > 0 && Math.floor(window.pageYOffset) !== Math.floor(elementToCenterOffset)
				) {
					let scrollTimeout;
					addEventListener("scroll", function scrollToElement() {
						clearTimeout(scrollTimeout);
						scrollTimeout = setTimeout(() => {
							actionAfterAlert(element);
							removeEventListener("scroll", scrollToElement);
						}, 200);
					});
					element.scrollIntoView({
						behavior: "smooth",
						block: "center",
					});
				} else {
					actionAfterAlert(element);
				}
			},
		});
	}
	return onInvalidSubmit;
}

const ValidationForm = {
	install(Vue, options) {
		Vue.component("ValidationForm", Form);
		Vue.component("Field", Field);
		Vue.provide("onInvalidSubmit", creatOnInvalidSubmit(Vue));
		Vue.provide("Yup", Yup);
	}
}

export default ValidationForm;