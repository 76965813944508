import * as nativeStore from './store/useNativeStore'
import * as vuexStore from './store/useVuexStore';
import createApp from './app'
import Vue3Storage from "vue3-storage";

const _vuexStore = vuexStore._createStore();

const _nativeStore = nativeStore._createStore();

if (window.__INITIAL_NATIVE_STATE__) {
	Object.keys(window.__INITIAL_NATIVE_STATE__).forEach((k) => {
		_nativeStore[k] = window.__INITIAL_NATIVE_STATE__[k];
	});
}

const { app, router, head } = createApp({
	nativeStore: _nativeStore,
	vuexStore: _vuexStore,
});
app.use(Vue3Storage, { namespace: "oright_" });

(async (r, a, h) => {
	await r.isReady();

	if (window.__INITIAL_STATE__) {
		window.__INITIAL_STATE__.headerStore.loggedIn = _vuexStore.state.headerStore.loggedIn;
		window.__INITIAL_STATE__.headerStore.userStatePromise = _vuexStore.state.headerStore.userStatePromise;
		_vuexStore.replaceState(window.__INITIAL_STATE__);
	}

	delete window.__INITIAL_STATE__
	delete window.__INITIAL_NATIVE_STATE__
	a.mount('#app', true);
})(router, app, head);
