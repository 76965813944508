import { createStore } from "vuex";
import { inject, provide } from "vue";
import isServer from "@/_base/isServer";
import headerModule from "./modules/header";
import categoriesProductsModule from "./modules/categoriesProducts";
import deviceModule from "./modules/device";
import pageLoadingModule from "./modules/pageLoading";

const StoreSymbol = Symbol("vuex-store")

export function _createStore() {
	return createStore({
		state() {
			return {
				isMobile: !isServer && window.innerWidth >= 992 ? false : true,
			};
		},
		mutations: {
			setIsMobileDevice(state, windowWidth) {
				state.isMobile = windowWidth >= 992 ? false : true;
			},
		},
		modules: {
			headerStore: headerModule,
			categoriesProductsStore: categoriesProductsModule,
			deviceStore: deviceModule,
			pageLoadingStore: pageLoadingModule,
		},
	});
}

export function provideStore(store) {
	provide(StoreSymbol, store);
}

export default function useStore() {
	const store = inject(StoreSymbol);
	if (!store) {
		throw Error("no store provided");
	}
	return store;
}

