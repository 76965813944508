<template>
	<div id="loading" v-show="pageLoading">
		<main class="main" v-show=!globalProperties.isServer>
			<div class="container">
				<div class="text-block">
					<svg class="spinner" viewBox="0 0 50 50">
						<circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
					</svg>
					<h1>
						<div class="one">{{ message.title }}</div>
						<div class="two">{{ message.text }}</div>
					</h1>
				</div>
			</div>
		</main>
	</div>
</template>
<script setup>
import { computed, getCurrentInstance } from "vue";
import { useStore } from "vuex";
const globalProperties = getCurrentInstance().appContext.config.globalProperties;
const store = useStore();
const message = computed(() => store.state.pageLoadingStore.loadingMessage);
const pageLoading = computed(() => store.state.pageLoadingStore.pageLoading);
</script>
<style lang="scss" scoped>
#loading {
	position: fixed;
	top: 0px;
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #fff;
	z-index: 1061;
	.main {
		width: 100%;

		.text-block,
		.text-block::after {
			animation-delay: 1s;
			animation-iteration-count: 1;
			animation-duration: 600ms;
			animation-fill-mode: both;
			animation-timing-function: cubic-bezier(0.0, 0.0, 0.2, 1);
		}

		.text-block {
			max-width: 500px;
			width: 100%;
			text-align: center;
			margin: auto;
			position: relative;
			animation-name: clip-text;
			padding: 1rem 0;

			&::after {
				content: "";
				position: absolute;
				z-index: 999;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: $brand-green;
				transform: scaleX(0);
				transform-origin: 0 50%;
				pointer-events: none;
				animation-name: text-revealer;
			}

			h1 {
				color: $brand-gray;

				.one {
					padding-top: .75rem;
					font-weight: 500;
					letter-spacing: 1.2px;
					font-size: 2.3rem;
					padding-bottom: .25rem;

					@media all and (min-width: 992px) {
						letter-spacing: 2px;
						font-size: 3rem;
					}
				}

				.two {
					font-size: 1rem;
					letter-spacing: 1.5px;
					font-weight: 500;
					display: inline-block;

					@media all and (min-width: 992px) {
						font-size: 1.3rem;
					}
				}
			}

			.spinner {
				animation: rotate 2s linear infinite;
				width: 50px;
				height: 50px;

				& .path {
					stroke: hsl(210, 70%, 75%);
					stroke-linecap: round;
					animation: dash 1.5s ease-in-out infinite;
				}

			}

			@keyframes rotate {
				100% {
					transform: rotate(360deg);
				}
			}

			@keyframes dash {
				0% {
					stroke-dasharray: 1, 150;
					stroke-dashoffset: 0;
				}

				50% {
					stroke-dasharray: 90, 150;
					stroke-dashoffset: -35;
				}

				100% {
					stroke-dasharray: 90, 150;
					stroke-dashoffset: -124;
				}
			}

			@keyframes clip-text {
				from {
					clip-path: inset(0 100% 0 0);
				}

				to {
					clip-path: inset(0 0 0 0);
				}
			}

			@keyframes text-revealer {

				0%,
				50% {
					transform-origin: 0 50%;
				}

				60%,
				100% {
					transform-origin: 100% 50%;
				}


				60% {
					transform: scaleX(1);
				}

				100% {
					transform: scaleX(0);
				}
			}
		}
	}
}
</style>