<template>
	<router-view name="loading" />
	<router-view name="popup" v-if="!pageLoading" />
	<!-- <router-view name="customerService" /> -->
	<Suspense>
		<router-view />
	</Suspense>
</template>
<script setup>
import { computed } from "vue";
import { useHead } from "@vueuse/head";
import { useStore } from "vuex";
import UAParser from "ua-parser-js";
useHead({
	titleTemplate: (title) =>
		!title
			? `${process.env.VUE_APP_TITLE_MAIN}`
			: `${title} | ${process.env.VUE_APP_TITLE_MAIN}`,
});
const store = useStore();
const pageLoading = computed(() => store.state.pageLoadingStore.pageLoading);
const parser = new UAParser();
store.commit("deviceStore/setDeviceInfo", parser.getResult());
</script>
<style lang="scss">
@import "../src/assets/scss/main.scss";
</style>
